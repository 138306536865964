import { Controller } from "@hotwired/stimulus"

import Toastify from 'toastify-js'
import elementHelper from "../helpers/element-helper";

const TEXT_CLASS = {
  success: 'text-success-content',
  error: 'text-error-content',
  warning: 'text-warning-content'
};

const ALERT_CLASS = {
  success: 'alert-success',
  error: 'alert-error',
  warning: 'alert-warning'
}

const ALERT_ICON = {
  success: 'check-square',
  error: 'xmark-square',
  warning: 'warning-square'
}

// Connects to data-controller="toast"
export default class extends Controller {
  static values = {
    flash: Object
  }

  connect() {
    Object.entries(this.flashValue).forEach(([type, message]) => this.#generateToast(type, message))
  }

  #generateToast = (type, message) => {
    const node = this.#buildToastNode(type, message);
    this.#toastify(node, type);
  }

  #buildToastNode = (type, message) => {
    const div = elementHelper.create('div', { className: `alert ${ALERT_CLASS[type]} rounded-lg gap-2.5` });
    const icon = elementHelper.create('i', { className: `iconoir-${ALERT_ICON[type]} text-2xl` });
    const text = elementHelper.create('span', { className: `${TEXT_CLASS[type]} my-0 truncate font-medium text-lg capitalize`, innerText: message });

    elementHelper.appendChildren(div, icon, text);

    return div;
  }

  #toastify = (node) => {
    Toastify({
      node,
      duration: 3000,
      className: `!rounded-lg !p-0`,
      stopOnFocus: true,
      gravity: 'bottom',
      position: 'right',
      style: { background: 'inherit' }
    }).showToast();
  }
}
