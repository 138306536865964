import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="checkout"
export default class extends Controller {
  static targets = ["actualPrice", "checkoutButton", "frequency", "planDescription", "planSelector", "planTitle", "salePrice"];
  static values = {
    plans: Array,
    planSelectorActiveClass: String
  }

  connect() {
    this.selectPlan();
  }

  selectPlan(event) {
    let button = event?.target || this.planSelectorTarget;
    let planId = button.dataset.checkoutPlanId;

    let currentPlan = this.plansValue.find((plan) => plan.id === planId);
    if (!currentPlan) throw "plan not found";

    this.#resetPlanSelectors(planId);
    this.#setTextInNodes(currentPlan);

  }

  #resetPlanSelectors(planId) {
    this.planSelectorTargets.forEach((obj) => {
      if (obj.dataset.checkoutPlanId === planId) obj.classList.add(this.planSelectorActiveClassValue)
      else obj.classList.remove(this.planSelectorActiveClassValue);
    })
  }

  #setTextInNodes(currentPlan) {
    this.planTitleTarget.innerText = currentPlan.title;
    this.actualPriceTarget.innerText = currentPlan.actual_price;

    if (this.hasSalePriceTarget) {
      this.salePriceTarget.innerText = currentPlan.sale_price;
    }

    this.frequencyTarget.innerText = currentPlan.frequency;
    this.planDescriptionTarget.innerText = currentPlan.description;
    this.checkoutButtonTarget.innerText = currentPlan.button_text;
    this.checkoutButtonTarget.dataset.priceId = currentPlan.id;
  }
}
