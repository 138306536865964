import { Controller } from "@hotwired/stimulus"

import { initializePaddle } from '@paddle/paddle-js';

// Connects to data-controller="paddle"
export default class extends Controller {
  static values = {
    account: String,
    customer: String,
    discountCode: String,
    environment: String,
    successUrl: String,
    token: String,
    user: String
  }

  paddle = null;

  connect() {
    initializePaddle({
      environment: this.environmentValue,
      token: this.tokenValue,
      eventCallback: (event) => {
        if (event.name === 'checkout.loaded') {
          const url = new URL(this.successUrlValue);
          url.searchParams.set("transaction_id", event.data.transaction_id);

          this.successUrlValue = url.toString();
        }
        else if (event.name === 'checkout.completed') {
          window.location.assign(this.successUrlValue);
        }
      }
    }).then((paddle) => {
      if (paddle) this.paddle = paddle;
    });
  }

  checkout(event) {
    this.paddle?.Checkout.open({
      customer: {
        id: this.customerValue
      },
      customData: {
        account_id: this.accountValue,
        user_id: this.userValue,
      },
      discountCode: this.discountCodeValue,
      items: [{ priceId: event.target.dataset.priceId }],
      settings: {
        displayMode: "overlay"
      },
    });
  }
}
