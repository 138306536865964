import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="refresh"
export default class extends Controller {
  static values = {
    after: Number
  }

  #timeoutId;

  connect() {
    this.#timeoutId = setTimeout(() => {
      clearTimeout(this.#timeoutId);

      window.location.reload()
    }, this.afterValue * 1000 || 2500);
  }
}
